<div class="footer">
  <div class="container-footer">
    <div class="content-left">
      <div class="title-footer-left">Let’s make something amazing together .</div>
      <div class="subtitle-footer-left">Start by <span>saying hi</span></div>
    </div>
    <div class="content-right">
      <div class="title-footer-right">Information</div>
      <div class="subtitle-footer-right">06.80.18.28.12</div>
      <div class="email">dharnaud77@hotmail.fr</div>
      <div class="nav-bar">
        <div class="item-nav"><span> < </span> Services <span> > </span></div>
        <div class="item-nav"><span> < </span> Works <span> > </span></div>
        <div class="item-nav"><span> < </span> Contact <span> > </span></div>
      </div>
    </div>
  </div>
  <div class="content-bottom">
   <div class="mobile-flex">
     <div class="title-bottom">David.H <span>Arnaud</span></div>
     <div class="subtitle-bottom">© 2024. All rights Reserved</div>
   </div>
    <div class="title-bottom desktop">David.H <span>Arnaud</span></div>
    <div class="subtitle-bottom desktop">© 2024. All rights Reserved</div>
    <div class="mobile-flex">
      <a href="https://www.linkedin.com/in/david-henri-arnaud/" class="logo-bottom">
        <img src="../../../../assets/images/footer/linkedin.svg" alt="linkedin">
      </a>
      <a href="https://github.com/DH7789-dev" class="logo-bottom ">
        <img src="../../../../assets/images/footer/github.svg" alt="github">
      </a>
    </div>
    <a href="https://www.linkedin.com/in/david-henri-arnaud/" class="logo-bottom desktop">
      <img src="../../../../assets/images/footer/linkedin.svg" alt="linkedin">
    </a>
    <a href="https://github.com/DH7789-dev" class="logo-bottom desktop">
      <img src="../../../../assets/images/footer/github.svg" alt="github">
    </a>
  </div>
</div>
