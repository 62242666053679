
<div class="overlay">
  <div class="overlay-left">
    <div class="content-top">
      <div class="title-job">Full Stack Developer</div>
      <div class="subtitle-job">Talk is cheap .<br><div class='tw1 '></div></div>
      <div class="paragraph-job">I design and code beautifully simple things, and I love  what I do.</div>
      <a class="button-chat" href="https://www.linkedin.com/in/david-henri-arnaud/">LET’S CHAT !</a>
    </div>
    <div class="content-bottom">
      <div class="content">
        <div class="number">4</div>
        <div class="paragraph">Years <br> Experiences</div>
      </div>
      <div class="content">
        <div class="number">20</div>
        <div class="paragraph">PROJECTS COMPLETED</div>
      </div>
    </div>
  </div>
  <div class="overlay-right">
    <div class="scene">
      <div class="sphere">
        <div class="hemisphere">
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/intil.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/git.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/gitk.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/html.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/java.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/js.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/typescript.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/nodejs.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/react.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/python.png" alt=""></div>
        </div>
        <div class="hemisphere">
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/bash.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/docker.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/css.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/figma.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/spring.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/angular.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/sass.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/Laravel.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/discord.png" alt=""></div>
          <div class="face"><img style="width:200px;height: 200px; position: relative; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="../../../assets/images/home/phps.png" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</div>
<button (click)="scroll('header')" style="position: fixed; bottom:100px; right:20px; background: #63F3AB" mat-fab color="primary" aria-label="Example icon button with a delete icon">
  <mat-icon style="color: #2D2E32;" aria-hidden="false" aria-label="Example home icon">keyboard_arrow_up</mat-icon>
</button>
<div  class="section-explain">
  <div class="container-explain">
    <div class="explain-left">
      <div class="content-explain" (mouseover)="changeText('first')">
        <div class="title-explain">Front-End</div>
        <div class="paragraph-explain">I develop front-end coding super smooth</div>
        <div class="number-project">20 PROJECTS</div>
      </div>
      <div class="content-explain" (mouseover)="changeText('second')">
        <div class="title-explain">Back-End</div>
        <div class="paragraph-explain">I develop back-end coding super smooth</div>
        <div class="number-project">15 PROJECTS</div>
      </div>
      <div class="content-explain" (mouseover)="changeText('third')">
        <div class="title-explain">Design</div>
        <div class="paragraph-explain">I develop front-end coding super smooth</div>
        <div class="number-project">4 PROJECTS</div>
      </div>
    </div>
    <div class="explain-right">
      <div class="container-explain-right">
        <div class="title-explain-right">Introduce</div>
        <div class="title-introduce">Hello ! I’m David-Henri Arnaud</div>
        <div  *ngIf="textNone == 'first'"class="subtitle-introduce">Every great developement front-end  begin with an better story</div>
        <div  *ngIf="textNone == 'second'"class="subtitle-introduce">Every great developement back-end begin with an better story</div>
        <div  *ngIf="textNone == 'third'"class="subtitle-introduce">Every great design begin with an better story</div>
        <div *ngIf="textNone == 'first'" class="paragraph-introduce first-paragraph">I started development with the integration of model which quickly allowed me to have a fairly high front-end experience and a mastery of html / css languages. With the different missions carried out, I was led to use js frameworks like react and angular which gives a completely new front-end perspective but just as interesting as developing natively.</div>
        <div *ngIf="textNone == 'second'" class="paragraph-introduce second-paragraph">I started back-end development not long after my first assignments because companies are mainly looking for full stack developers, which led me to discover languages such as javascript / typescript with nodejs, then java to develop office software and spring boot to make restfull APIs to boost the different models that I make for my customers, which has led me to offer better quality back-end services according to customer needs</div>
        <div *ngIf="textNone == 'third'" class="paragraph-introduce second-paragraph">To add value to the client and avoid the costs of a UX / UI designer, I started offering my own model to the client according to his needs and desires but also according to his core business which was important for a realization. complete and efficient. I quickly realized that the usefulness of the agile method was important to realize these models but also at the level of the development which proves to be more and more appreciated by my customers in the realization of their need because better understood thanks to a specification. flexible fillers.</div>
      </div>
    </div>
  </div>
</div>
<div class="section-company">
  <div class="container-company">
    <a href="https://www.thalesgroup.com/fr" class="company-image ">
      <img src="../../../assets/images/home/thales.svg" alt="logo thales">
    </a>
    <a href="https://webind.co/" class="company-image mobile">
      <img src="../../../assets/images/home/webind.svg" alt="logo webind">
    </a>
    <a href="https://www.conforama.fr/" class="company-image top">
      <img src="../../../assets/images/home/confo.svg" alt="logo conforama">
    </a>
    <a href="https://www.edf.fr/" class="company-image">
      <img src="../../../assets/images/home/edf.svg" alt="logo edf">
    </a>
  </div>
</div>
<div class="section-project">
  <div class="title-project">
    <div class="title">Latest Works</div>
    <div class="subtitle">Perfect solutions for digital experience</div>
  </div>

  <div class="container-project ">
    <a href="https://weworkstudio.fr/" class="content-project project2 mobile" style="background-image: url('../../../assets/images/home/project1.png')">
      <div class="content-techno-project">
        <div class="content-header">
          <div class="title-techno-project">Project 1</div>
          <div class="container-techno">
            <div class="title-techno techno1"><div class="techno">ReactJS</div></div>
            <div class="title-techno techno2"><div class="techno">NestJs</div></div>
            <div class="title-techno techno5"><div class="techno">TS</div></div>
          </div>
        </div>
        <div class="paragraph-project">Weworkstudio is a company producing web products</div>
      </div>
    </a>
    <a href="https://alexia-spiesser-ferrette.com/" class="content-project project1" style="background-image: url('../../../assets/images/home/project2.png')">
      <div class="content-techno-project">
        <div class="content-header">
          <div class="title-techno-project">Project 2</div>
          <div class="container-techno">
            <div class="title-techno techno3"><div class="techno">ReactJS</div> </div>
            <div class="title-techno techno4"><div class="techno">NestJs</div></div>
            <div class="title-techno techno2"><div class="techno">JS</div></div>
          </div>
        </div>
        <div class="paragraph-project">Alexia is a client who wants to have an online presence to show all of her projects</div>
      </div>
    </a>
    <a href="https://weworkstudio.fr/" class="content-project project2 desktop" style="background-image: url('../../../assets/images/home/project1.png')">
      <div class="content-techno-project">
        <div class="content-header">
          <div class="title-techno-project">Project 1</div>
          <div class="container-techno">
            <div class="title-techno techno1"><div class="techno">ReactJS</div></div>
            <div class="title-techno techno2"><div class="techno">NestJs</div></div>
            <div class="title-techno techno5"><div class="techno">TS</div></div>
          </div>
        </div>
        <div class="paragraph-project">Weworkstudio is a company producing web products</div>
      </div>
    </a>
    <div class="all-project desktop">
      <div class="title-all">ALL PROJECTS</div>
      <div class="subtitle-all">*Some projects not allow publish, if you want to see more,<span style="color:#63F3AB;">contact</span></div>
    </div>
    <a href="https://jocelyne-heng.fr/" class="content-project project3" style="background-image: url('../../../assets/images/home/project3.png')">
      <div class="content-techno-project">
        <div class="content-header">
          <div class="title-techno-project">Project 3</div>
          <div class="container-techno">
            <div class="title-techno techno5"><div class="techno">Wordpress</div> </div>
            <div class="title-techno techno4"><div class="techno">PHP</div></div>
            <div class="title-techno techno2"><div class="techno">JS</div></div>
          </div>
        </div>
        <div class="paragraph-project">Jocelyne Futou Hang was a mission for a massage company that wanted to have more visibility and accessibility for its customers.</div>
      </div>
    </a>
    <div class="all-project mobile">
      <div class="title-all">ALL PROJECTS</div>
      <div class="subtitle-all">*Some projects not allow publish, if you want to see more, <span style="color:#63F3AB;">contact</span></div>
    </div>
  </div>
</div>

<div class="section-university">
  <div class="title-university">University</div>
  <div class="container-university">
    <div class="content-university">
      <div class="date-university">2021-2023</div>
      <div class="title-certificate">Master II Achitecte logiciels <span> <br> ESGI - Paris Nation</span></div>
    </div>
    <div class="content-university">
      <div class="date-university mright" >2021</div>
      <div class="title-certificate">Bachelor Achitecte logiciels <span> <br>ESGI - Paris Nation</span></div>
    </div>
    <div class="content-university">
      <div class="date-university mright" >2020</div>
      <div class="title-certificate">BTS SIO <span> <br>CFA campus Montsouris Paris</span></div>
    </div>
  </div>
</div>
