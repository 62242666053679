<div class="header">
    <div class="container-header">
        <div class="title-logo">David.H <span>Arnaud</span></div>
        <div class="header-content">
          <nav class="menu exp">
            <div  (click)="scroll('section-explain')" class="menu-item"><span style="margin-right: 5px;"> <  </span> Services <span style="margin-left: 5px;"> > </span></div>
            <div  (click)="scroll('section-project')" class="menu-item"><span style="margin-right: 5px;"> < </span> Works <span style="margin-left: 5px;"> > </span></div>

          </nav>
          <div class="logo-container">
            <a href="https://github.com/DH7789-dev" class="logo-git">
              <div class="logo-img"></div>
              <div class="title">GitHub</div>
            </a>
            <div style="cursor:pointer;" class="contact" (click)="scroll('footer')">
              <div class="image-contact"></div>
            </div>
          </div>
        </div>
    </div>

</div>
